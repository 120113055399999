import { IDataCar } from ".";

export default {
   id: "car_2008",
   urlPageId: "2008",
   title: "PEUGEOT 2008",
   shortTitle: "2008",
   colors: [
      { colorHex: "#7b7d84", title: "Gri (Selenyum)", dir: "gri", featured: true },
      // TODO release comment below
      { colorHex: "#BEBEBE", title: "Gri (Tekno)", dir: "gri_tekno" },
      // { colorHex: "#006cb7", title: "Mavi (Vertigo)", dir: "mavi" },
      { colorHex: "#851a26", title: "Kırmızı (Elixir)", dir: "kirmizi" },
      { colorHex: "#222222", title: "Siyah (İnci)", dir: "siyah" },
      { colorHex: "#e3e4e6", title: "Beyaz (Okenit)", dir: "beyaz" },
   ],
   infoLink: "https://www.peugeot.com.tr/peugeot-modelleri/2008.html",
   otherPackagesLink: "https://www.peugeot.com.tr/peugeot-modelleri/2008.html",
   broshureLink: "https://www.peugeot.com.tr/content/dam/peugeot/turkey/b2c/bro%C5%9F%C3%BCrler/2023/agustos/2008SUV_ModelBrosuru.pdf",
   appointmentLink: '2008/suv',
   galleryPhotos: [
      { source: "gallery_0.jpg" }, { source: "gallery_1.jpg" }, { source: "gallery_2.jpg" },
      { source: "gallery_3.jpg" }, { source: "gallery_4.jpg" }, { source: "gallery_5.jpg" },
      { source: "gallery_6.jpg" }, { source: "gallery_7.jpg" }, { source: "gallery_8.jpg" },
   ],
   videos: [
      { title: "Yeni PEUGEOT 2008 - Göz Alıcı Öz Güven", type: "youtube", source: "PWaBblGortQ" },
   ],
   interiorImageNames: [
      "interior_0", "interior_1", "interior_2", "interior_3", "interior_4", "interior_5",
   ],
   pinsInterior: [
      {
         id: "screen", coords: { x: 95.12, y: -5.49 },
      },
      {
         id: "cockpit", coords: { x: 76.39, y: -5.97 },
      },
      {
         id: "interiordesign", coords: { x: 91.93, y: 7.88 },
      },
      {
         id: "interiordesign", coords: { x: 52.19, y: -22.13 },
      },
      {
         id: "interiordesign", coords: { x: -84.99, y: 65.08 },
      },
      {
         id: "midconsole", coords: { x: 94.17, y: -40 },
      },
      {
         id: "seats", coords: { x: 63.63, y: -46.24 },
      },
      {
         id: "trunk-i", coords: { x: -88.50, y: -18.24 },
      },
   ],
   featuredPinFrameIndex: 35,
   pinFrames: [
      { index: 0, pins: [{ id: "signitures", posXPerc: 78.61, posYPerc: 55.16 },] },
      { index: 1, pins: [{ id: "signitures", posXPerc: 79.39, posYPerc: 54.18 },] },
      { index: 2, pins: [{ id: "signitures:2", posXPerc: 41.5, posYPerc: 28.7 },] },
      { index: 3, pins: [{ id: "signitures:2", posXPerc: 42.5, posYPerc: 29.03 },] },
      { index: 4, pins: [{ id: "signitures:2", posXPerc: 43.72, posYPerc: 28.7 },] },
      { index: 5, pins: [{ id: "signitures:2", posXPerc: 45.17, posYPerc: 28.54 },] },
      { index: 6, pins: [{ id: "signitures:2", posXPerc: 47.5, posYPerc: 29.2 },] },
      { index: 7, pins: [{ id: "signitures:2", posXPerc: 49.28, posYPerc: 29.03 },] },
      { index: 8, pins: [{ id: "signitures:2", posXPerc: 51.17, posYPerc: 29.2 },] },
      { index: 9, pins: [{ id: "drivesupport", posXPerc: 17.61, posYPerc: 59.16 }, { id: "signitures:2", posXPerc: 51.39, posYPerc: 28.54 },] },
      { index: 10, pins: [{ id: "drivesupport", posXPerc: 23.28, posYPerc: 60.17 }, { id: "signitures:3", posXPerc: 34.72, posYPerc: 40.02 },] },
      { index: 11, pins: [{ id: "drivesupport", posXPerc: 31.16, posYPerc: 60 }, { id: "signitures:3", posXPerc: 42.5, posYPerc: 40.68 },] },
      { index: 12, pins: [{ id: "drivesupport", posXPerc: 39.94, posYPerc: 59.65 }, { id: "signitures:3", posXPerc: 49.94, posYPerc: 40.68 },] },
      { index: 13, pins: [{ id: "drivesupport", posXPerc: 40.83, posYPerc: 59.65 }, { id: "signitures:3", posXPerc: 57.39, posYPerc: 40.35 },] },
      { index: 14, pins: [{ id: "drivesupport", posXPerc: 49.28, posYPerc: 59.48 }, { id: "signitures:3", posXPerc: 34.06, posYPerc: 40.18 },] },
      { index: 15, pins: [{ id: "drivesupport", posXPerc: 51.83, posYPerc: 59.32 }, { id: "signitures:3", posXPerc: 41.94, posYPerc: 40.52 },] },
      { index: 16, pins: [{ id: "drivesupport", posXPerc: 59.06, posYPerc: 59.48 }, { id: "signitures:3", posXPerc: 50.5, posYPerc: 40.52 },] },
      { index: 17, pins: [{ id: "drivesupport", posXPerc: 65.94, posYPerc: 59.32 }, { id: "signitures:3", posXPerc: 58.83, posYPerc: 40.85 },] },
      { index: 18, pins: [{ id: "drivesupport", posXPerc: 73.83, posYPerc: 59.15 }, { id: "signitures:3", posXPerc: 67.06, posYPerc: 40.68 },] },
      { index: 19, pins: [{ id: "drivesupport", posXPerc: 80.61, posYPerc: 59.32 }, { id: "signitures:2", posXPerc: 48.39, posYPerc: 28.37 },] },
      { index: 20, pins: [{ id: "signitures:2", posXPerc: 49.5, posYPerc: 28.54 },] },
      { index: 21, pins: [{ id: "signitures:2", posXPerc: 51.5, posYPerc: 28.87 },] },
      { index: 22, pins: [{ id: "signitures:2", posXPerc: 54.06, posYPerc: 28.7 },] },
      { index: 23, pins: [{ id: "signitures:2", posXPerc: 56.61, posYPerc: 28.7 },] },
      { index: 24, pins: [{ id: "signitures:2", posXPerc: 58.83, posYPerc: 29.03 },] },
      { index: 25, pins: [{ id: "signitures:2", posXPerc: 60.61, posYPerc: 29.2 },] },
      { index: 26, pins: [{ id: "signitures:2", posXPerc: 62.39, posYPerc: 29.03 },] },
      { index: 27, pins: [{ id: "signitures", posXPerc: 21.17, posYPerc: 56.66 },] },
      { index: 28, pins: [{ id: "signitures", posXPerc: 29.94, posYPerc: 55.16 },] },
      { index: 29, pins: [{ id: "signitures", posXPerc: 33.61, posYPerc: 55.66 },] },
      { index: 30, pins: [{ id: "signitures", posXPerc: 41.94, posYPerc: 55.66 },] },
      { index: 31, pins: [{ id: "signitures", posXPerc: 47.83, posYPerc: 56.82 },] },
      { index: 32, pins: [{ id: "signitures", posXPerc: 51.83, posYPerc: 57.33 },] },
      { index: 33, pins: [{ id: "signitures", posXPerc: 59.72, posYPerc: 56.66 },] },
      { index: 34, pins: [{ id: "signitures", posXPerc: 62.72, posYPerc: 55.82 },] },
      { index: 35, pins: [{ id: "signitures", posXPerc: 69.06, posYPerc: 55.32 },] },
   ]
} as IDataCar;